body.active-modal {
    overflow: hidden;
    /* touch-action: none; */
}

#modal {
    opacity: 0;
    touch-action: none;
    pointer-events: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transition: opacity 0.2s linear;
    background-color: rgba(0, 0, 0, 0.5);
}
#modal .modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 50vw;
    max-width: 90vw;
    min-height: 30vh;
    max-height: 100vh;
    background-color: var(--color-white);
    padding: 1%;
    box-sizing: border-box;
    overflow-y: scroll;
    border-radius: 3px;
}

body.active-modal #modal {
    opacity: 1;
    touch-action: auto;
    pointer-events: all;
}
#modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
#modal .modal-header img {
    cursor: pointer;
}
#modal .modal-header h3 {
    font-size: 20px;
    font-weight: 600;
}
#modal .modal-content {
    position: relative;
}

.result-message {
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid var(--color-gray);
}

#modal .container {
    width: 100%;
}
@media (max-width: 1199px) {
    #modal .modal-container {
        width: 80vw;
    }
}
@media (max-width: 600px) {
    #modal .modal-container {
        width: 90vw;
    }
}
