:root {
    --color-black: #000;
    --color-white: #fff;
    --color-gray: #7b7b7b;
    --color-light-gray: #cdcdcd;

    --color-primary: #000000;
    --color-info: #0068ff;
    --color-error: #ff0000;
    --color-secondary: #ff9e37;
    --color-success: #70ff75;
    /* --color-error: #fa5d5d; */

    --color-warning: #f7ff9b;
    /* --color-info: #3d77c8; */

    --color-disabled: #cdcdcd;

    --shadow-button: -3px 3px 8px rgba(0, 0, 0, 0.3);
    --shadow-container: -5px 5px 8px rgba(0, 0, 0, 0.5);

    --swiper-theme-color: var(--color-black);
    --swiper-navigation-sides-offset: 0px;
    --swiper-pagination-bottom: 0px;
}
@font-face {
    font-family: 'SUIT-Variable';
    font-weight: normal, 100, 200, 300, 400, 500, 600, 700, 800, 900;
    src: url('../fonts/SUIT-Variable.ttf');
}
@font-face {
    font-family: 'NotoSans';
    font-weight: normal, 100, 200, 300, 400, 500, 600, 700, 800, 900;
    src: url('../fonts/NotoSansKR-VariableFont_wght.ttf');
}
.primary {
    color: var(--color-white);
    background-color: var(--color-primary);
}
.secondary {
    color: var(--color-black);
    background-color: var(--color-secondary);
}
.warning {
    color: var(--color-black);
    background-color: var(--color-warning);
}
.success {
    color: var(--color-white);
    background-color: var(--color-success);
}
.error {
    color: var(--color-white);
    background-color: var(--color-error);
}
.info {
    color: var(--color-white);
    background-color: var(--color-info);
}
.normal {
    color: var(--color-white);
    background-color: var(--color-gray);
}
.error-text {
    color: var(--color-error);
}
.disabled {
    background-color: var(--color-gray);
    color: var(--color-white);
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
