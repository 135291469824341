html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 15px;
    font-family: 'NotoSans';
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}
body {
    width: 100%;
    height: 100%;
    margin: 0;
    overscroll-behavior: none;
    position: relative;
    /* font-family: 'SUIT-Variable'; */

    font-size: 1rem;
}
body::-webkit-scrollbar {
    width: 5px;
}
.list .content::-webkit-scrollbar {
    width: 0;
    height: 0;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
}
*::-webkit-scrollbar-track {
    background-color: var(--color-white);
}
div.tool {
    /* position: sticky; */
    top: 0;
    background-color: var(--color-white);
}
#app {
    width: 100%;

    height: 100%;
    margin: 0;
    max-width: 1920px;
    margin: auto;
}
header {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
}
header div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 250px;
}

header img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
/* footer {
    padding: 0 100px;
    box-sizing: border-box;
    text-align: center;
} */
.global_guide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: auto;
    padding-top: 30px;
    gap: 10px;
}
.global_guide h1 {
    text-align: center;
    word-break: keep-all;
    line-height: 2.5rem;
}

.global_guide .buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    word-break: keep-all;
}
.global_guide button {
    font-size: 1rem;
    line-height: 1.3rem;
    padding: 10px 20px;
    margin-top: 15px;
    border-radius: 5px;
    box-shadow: var(--shadow-button);
    color: var(--color-white);
    margin: 10px auto;
}
.global_guide table {
    border: 0;
}
.global_guide table td {
    border: 0;
    /* font-weight: 300; */
    line-height: 1.3rem;
    font-size: 1rem;
    word-break: keep-all;
}
.global_guide table th {
    border: 0;
    white-space: nowrap;
    font-weight: 700;
}

/* footer .banner ul {
    padding-top: 20px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
}
footer .banner ul li {
    width: 150px;
}
footer .banner ul li img {
    width: 100%;
    height: 80px;
    object-fit: contain;
} */
/* footer .company {
    text-align: center;
    padding: 10px 0;
    box-sizing: border-box;
}
footer .company ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
footer .company ul li {
    padding: 0 5px;
}
footer .company div {
    margin-bottom: 5px;
}
footer .license {
    display: flex;
    justify-content: center;
    gap: 30px;
}
footer .license div img {
    height: 4.5rem;
}
footer .license ul {
    display: block;
    text-align: start;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    letter-spacing: 0px;
}
footer .license ul li {
    display: inline-block;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid;
}
footer .license ul li:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}

footer .copy {
    line-height: 13px;

    padding: 10px 0;
} */

/* style start */
.sub-header {
    padding: 10px 30px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    font-size: 0.9rem;
}
.sub-header span b {
    font-size: 0.8rem;
    color: var(--color-gray);
}
.sub-title {
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('https://plus.unsplash.com/premium_photo-1661301110025-8a6bfbc55349?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZWxlY3Ryb25pYyUyMGZhY3Rvcnl8ZW58MHx8MHx8fDA%3D');
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-title div {
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
}
.sub-title h3 {
    font-size: 2.2rem;
    font-weight: 650;
}
.sub-title h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 20px;
}

table.table {
    width: 70%;
    border: 0;
}
table.table th,
table.table td {
    padding: 10px 20px;
    box-sizing: border-box;
    border: 0;
    text-align: start;
}

.agreement {
    margin: 40px 0;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}
.agreement .content {
    width: 500px;
}
.agreement li {
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.agreement li label {
    font-weight: 500;
    width: inherit;
}
.agreement li button {
    padding: 3px;
    border: 1px solid var(--color-black);
    background-color: var(--color-light-gray);
    font-size: 15px;
}
.agreement h5 {
    font-size: 20px;
    font-weight: 650;
    color: var(--color-primary);
    margin-bottom: 12px;
}

.file-button {
    width: 500px;
    height: 2rem;
    margin-bottom: 15px;
}
.file-button img {
    display: inline-block;
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
}
.file-button span {
    display: inline-block;
    font-size: 12px;
    margin-left: 5px;
}
.image-preview {
    height: 700px;
}
.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navigation {
    font-size: 14px;
    margin: auto;
    /* width: 60%; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--color-light-gray);
    border-bottom: 1px solid var(--color-light-gray);
}
.navigation a {
    flex: 1;
    height: 32px;
    border: 0.5px solid var(--color-light-gray);
    box-sizing: border-box;
    /* box-shadow: 0px 0px 1px 1px var(--color-light-gray); */
}
.navigation a.active {
    background-color: var(--color-light-gray);
    font-weight: 650;
}
/* 여기서부터 새로  */

.sub-content {
    width: 80%;
    font-size: 1.3rem;
    line-height: 1.5rem;
}
.sub-content h1 {
    margin-bottom: 10px;
    line-height: 3rem;
    font-size: 2rem;
}
.sub-content h2 {
    margin-bottom: 10px;
    line-height: 2rem;
}
.sub-content p {
    line-height: 1.5rem;
    margin-bottom: 1rem;
}
/* default  */
.container {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    align-items: center;
    font-size: 1rem;
}
.container.content {
    width: 50%;
}
.container.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 90%;
    gap: 20px;
    align-items: stretch;
}

.container .signin form {
    width: 100%;
}
.container form .auth-list {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 0.8rem; */
}
.container .signin form .auth-list {
    height: 1rem;
    margin-bottom: 15px;
}
.container .signin form .links {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* font-size: 0.75rem; */
}
.container h5.title {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
}
.container .tip {
    margin: 30px 0;
    color: var(--color-error);
    text-align: center;
    display: block;
    list-style: circle;
    padding: 0 10px;
    box-sizing: border-box;
}
.container .tip li {
    margin-bottom: 5px;
    font-size: 15px;
}
.container form {
    width: 90%;
    margin: auto;
}
.container .wrapper {
    width: 100%;
    margin-bottom: 5px;
}
.container .wrapper .label {
    margin-right: 10px;
    align-content: center;
    display: inline-grid;
}
.container .wrapper button,
.container .wrapper > .file-label,
.container .wrapper > div.switch:not(.input) {
    margin-left: 10px;
}
.container .wrapper .label,
.container .wrapper button,
.container .wrapper > .file-label,
div.switch {
    font-size: 1rem;
    font-weight: 600;
    word-break: keep-all;
    white-space: initial;
    height: 3rem;
    vertical-align: middle;
    cursor: pointer;
    align-content: center;
    vertical-align: top;
}

.container .wrapper button,
.container .wrapper > .file-label,
.container .wrapper .label {
    display: inline-grid;
    align-content: center;
}
.container .wrapper .label,
.container .wrapper button,
.container .wrapper > .file-label {
    width: 100px;
}
div.switch {
    width: 98px;
    height: calc(3rem - 2px);
}
div.switch.input {
    line-height: 3rem;
}
.container .wrapper > input,
.container .wrapper > select,
.container .wrapper > textarea,
.container .wrapper .upload-list,
div.switch.input,
.container .wrapper > span {
    width: calc(100% - 120px);
}
.container .wrapper > span > * {
    display: initial;
    width: inherit;
    height: initial;
    text-decoration: initial;
    appearance: initial;
}
.container .wrapper:has(button) > input,
.container .wrapper:has(div.switch) > input,
.container .wrapper:has(div.switch) > select,
.container .wrapper:has(label.file-label) > div.upload-list {
    width: calc(100% - 230px);
}
.container .wrapper > input,
.container .wrapper > select,
.container .wrapper > textarea,
.container .wrapper .upload-list,
div.switch.input,
.container .wrapper > span,
.container .wrapper .items {
    display: inline-grid;
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border: 0;
    min-height: 3rem;
    box-sizing: border-box;
    padding: 0.5rem;
    box-sizing: border-box;
    background-color: var(--color-white);
    /* margin: 0 10px; */
    vertical-align: middle;
    word-break: keep-all;
    margin: 0;
}

.container .wrapper > span {
    line-break: anywhere;
    align-content: center;
}
.container .wrapper > input,
.container .wrapper > select,
.container .wrapper > textarea,
.container .wrapper .upload-list,
div.switch.input {
    border: 1px solid var(--color-gray);
    font-size: 1rem;
    border-radius: 3px;
    align-content: center;
}
.container .wrapper .upload-list span {
    color: var(--color-gray);
}
.container .wrapper > select {
    background-image: url('../icons/arrow_icon.svg');
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 16px;
}
.container .wrapper > textarea {
    min-height: 6rem;
    resize: vertical;
    align-content: baseline;
}
div.switch input {
    appearance: none;
    -webkit-appearance: none;
    display: none;
}
div.switch {
    display: inline-table !important;
    padding: 0 !important;
    border: 1px solid var(--color-gray);
    border-radius: 3px;
}
div.switch label {
    display: table-cell;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    align-content: center;
}
div.switch label:not(:last-child) {
    border-right: 1px solid var(--color-gray);
}
div.switch label:has(input:checked) {
    background-color: var(--color-gray);
    color: var(--color-white);
}
.container .wrapper > button,
.container .wrapper > .file-label {
    background-color: var(--color-gray);
    color: var(--color-white);
    border-radius: 3px;
}
.container .wrapper > .file-label {
    text-align: center;
}
/* 폼 디자인 수정 여기까지 */
.container .wrapper .items.flex {
    display: inline-flex;
}
.container .wrapper .items .item {
    /* display: block; */
    /* width: 100%; */
    /* min-height: 1rem; */
    margin-bottom: 5px;
}

.container .wrapper .upload-list > div {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    height: 2rem;
}
.container .wrapper .upload-list > div:not(:last-child) {
    border-bottom: 1px solid var(--color-light-gray);
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.container .wrapper .upload-list > div > span {
    line-height: calc(3rem - 10px);
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* cursor: pointer; */
}
.container .wrapper .upload-list > div > div {
    /* width: 100px; */
    display: flex;
    align-items: center;
    gap: 10px;
}
.container .wrapper .upload-list > div > div > span {
    color: var(--color-white);
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
    padding: 0 5px;
    line-height: 1.5rem;
}

.container .tool-tip {
    font-size: 11px;
    margin-left: 120px;
    color: var(--color-gray);
    margin-top: 5px;
}
.container .guide {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}
/* message container */
div.message-container {
    max-height: 13px;
    font-size: 13px;
    text-align: center;
    color: var(--color-error);
    padding: 10px 0;
}
/* content list */
.list.preview {
    width: 100%;
    /* width: 600px; */
    margin-bottom: 20px;
}
.list.service,
.list.board,
.list.user {
    /* width: 1300px; */
    width: 100%;
}

.list .header {
    height: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 15px;
}
.list .content {
    width: 100%;
    border-radius: 3px;
    border: 0px solid var(--color-gray);
    overflow-x: scroll;
    box-shadow: 0 0 0 1px var(--color-gray) inset;
}

.list .content table {
    width: 100%;
    border-style: hidden;
    font-size: 14px;
    text-align: center;
    font-size: 1rem;
    table-layout: fixed;
}
.list .content table tr {
    height: 40px;
}
.list .content td,
.list .content th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list .content table thead tr th {
    background-color: var(--color-gray);
    color: var(--color-white);
    border-right: 1px solid var(--color-light-gray);
}
.list .content table th,
.list .content table td {
    align-content: center;
    display: table-cell;
    vertical-align: middle;
}
.list.preview table tbody tr,
.list.service table tbody tr,
.list.board table tbody tr,
.list.user table tbody tr {
    cursor: pointer;
}
.list.preview table tbody tr:hover,
.list.service table tbody tr:hover,
.list.board table tbody tr:hover,
.list.user table tbody tr:hover {
    background-color: var(--color-light-gray);
}
.contact {
    width: 100%;
    margin-bottom: 20px;
    font-size: 1rem;
    text-align: center;
    /* padding: 15px 0; */
}
.contact p {
    display: inline;
    margin: 0 5px;
    line-height: 1.3rem;
}
.container.list .filter {
    width: 50%;
    margin-bottom: 15px;
}
.container .buttons {
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin: auto;
}
.container .buttons.center {
    justify-content: center;
}
.container .buttons.right {
    justify-content: end;
}
.container .buttons.left {
    justify-content: start;
}

.container .buttons button,
.container .buttons a {
    min-width: 100px;
    height: 40px;
    border-radius: 3px;
    /* box-shadow: var(--shadow-button); */
}
/* Pagination  */
.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-top: 20px;
}

.pagination span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 1rem;
    cursor: pointer;
    color: var(--color-black);
    padding: 3px;
    border-radius: 50%;
}
.pagination span.disabled {
    background-color: var(--color-primary);
    color: var(--color-white);
}
.main-banner {
    width: 100%;
    object-fit: cover;
}
.excel-button {
    padding: 10px 0;
    text-align: end;
}
.excel-button button {
    background-color: #0b6100;
    color: var(--color-white);
    padding: 10px;
    border-radius: 3px;
}

.loading-panel {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.loading-panel img {
    width: 100px;
    object-fit: contain;
    animation: rotate 2s linear infinite;
}

.warning-message {
    border: 1px solid var(--color-light-gray);
    padding: 20px;
}
.warning-message h4 {
    font-weight: 650;
    margin-bottom: 15px;
    line-height: 1.2rem;
}
.warning-message ol {
    list-style: decimal;
    margin-left: 1rem;
}
.warning-message ol > li {
    margin-bottom: 10px;
}
.warning-message ol > li > dl > dt {
    margin-bottom: 8px;
}
/* .warning-message ol > li > dl > dt::before {
    content: '*';
} */
.warning-message ol > li > dl > dd > dl {
    margin-bottom: 5px;
}
.warning-message ol > li > dl > dd > dl > dt {
    margin-bottom: 5px;
}
.warning-message ol > li > dl > dd > dl > dt::before {
    content: '▪︎ ';
    vertical-align: text-top;
}
.warning-message ol > li > dl > dd > dl > dd {
    margin-left: 1rem;
}
.warning-message ol > li > dl > dd > dl > dd > ul {
    list-style: inside;
}
.warning-message ol > li > dl > dd > dl > dd > ul > li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
.warning-message ol > li > dl > dd > dl > dd > ul > li {
    margin-bottom: 3px;
}
.wrapper.check {
    text-align: center;
    margin-top: 10px;
}
.family-sites {
    position: absolute;
    right: -20%;
    top: 50%;
    transform: translateY(-50%);
}
.family-sites label {
    font-weight: 650;
}
.family-sites select {
    display: block;
    margin-top: 0.5rem;
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    background-color: var(--color-white);
    background-image: url('../icons/arrow_icon.svg');
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 16px;
    color: var(--color-black);
    padding: 0.4rem;
}
.images {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.images img {
    margin-top: 3rem;
    width: 90%;
    max-width: 1000px;
}
.mobile {
    display: none;
}
.desktop {
    display: block;
}
.as-container.image {
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* box-shadow: 3px 5px 0px var(--color-gray); */
    border-radius: 0;
    /* margin-bottom: 5px; */
    margin-left: 110px;
    width: calc(100% - 110px);
}
.as-container img {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    object-fit: contain;
    object-position: center;
}
.as-container {
    width: 100%;
    border: 1px solid var(--color-gray);
    border-radius: 3px;
}
.as-container .header {
    background-color: var(--color-gray);
    color: var(--color-white);
    padding: 10px;
    box-sizing: border-box;
}
.as-container .body {
    padding: 10px 50px;
}
.as-container .footer {
    font-size: 0.8rem;
    box-sizing: border-box;
    padding: 5px;
    text-align: end;
}
.as-container .result-container {
    font-weight: 650;
    font-size: 1.1rem;

    display: flex;
}
.as-container .result-container span {
    display: inline-block;
    font-size: 0.9rem;
}
.as-container .result-container span:nth-child(2) {
    border-bottom: 2px solid;
    width: 20rem;
}
.as-container .result-container,
.as-container .type-container,
.as-container .option-container {
    line-height: 2rem;
    display: flex;
    gap: 20px;
}
.as-container input[type='date'],
.as-container select {
    width: 10rem;
    height: 1.5rem;
    font-size: 1rem;
    padding: 0;
    line-height: 1.5rem;
    outline: 0;
    border: 1px solid var(--color-gray);
    border-radius: 3px;
    margin: 0 5px;
}
.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    justify-content: center;
    align-items: center;
}
.popup.mobile {
    display: none;
}
.popup.desktop {
    display: flex;
}
.popup-wrapper {
    width: 600px;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
}
.popup-wrapper .popup-item {
}
.popup-wrapper .popup-item .header {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 650;
}
.popup-wrapper .popup-item img {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
    object-position: center;
}
.popup-wrapper .popup-item .controls {
    position: relative;
    display: flex;
    justify-content: end;
    gap: 10px;
    font-size: 0.9rem;
    vertical-align: middle;
    z-index: 100;
    padding: 5px;
    box-sizing: border-box;
}
.popup-wrapper .popup-item .controls button {
    color: var(--color-white);
    background-color: var(--color-primary);
    padding: 5px;
}

/* Tablet Style */
@media (max-width: 1199px) {
    html {
        font-size: 15px;
    }
    header {
        height: 80px;
        padding: 0 20px;
    }
    /* footer {
        padding: 0;
    }
    footer .license {
        flex-direction: column;
        gap: 0;
    }
    footer .license div {
        padding: 0 30px;
    }
    footer .license div img {
        height: 70px;
    } */
    .global_guide {
        width: 80%;
    }

    .navigation {
        /* width: 90%; */
        display: inline-block;
    }
    .navigation a,
    .navigation p {
        width: 33.3333%;
    }
    .container {
        width: 80%;
    }

    .container.row {
        grid-template-columns: initial;
        grid-auto-rows: initial;
    }

    .modal-container .container {
        width: 800px;
    }
    .container.list .filter {
        width: 70%;
    }
    .container.content {
        width: 80%;
    }
    .container .tool-tip {
        margin-left: 105px;
    }
    .container .wrapper .label {
        margin-right: 5px;
    }
    .container .wrapper button,
    .container .wrapper > .file-label,
    .container .wrapper > div.switch:not(.input) {
        margin-left: 5px;
    }

    .container .wrapper > input[type='text'],
    .container .wrapper > input[type='password'],
    .container .wrapper > input[type='date'],
    .container .wrapper > input[type='email'],
    .container .wrapper > select,
    .container .wrapper > textarea,
    .container .wrapper > span,
    div.switch.input,
    .container .wrapper .upload-list,
    .container .wrapper .items {
        width: calc(100% - 105px);
    }
    .container .wrapper:has(button) > input,
    .container .wrapper:has(div.switch) > input,
    .container .wrapper:has(div.switch) > select,
    .container .wrapper:has(label.file-label) > div.upload-list {
        width: calc(100% - 210px) !important;
    }

    .list.service,
    .list.board,
    .list.user {
        width: 100%;
    }

    .pagination {
        gap: 10px;
    }
    .pagination span {
        width: 2rem;
        aspect-ratio: 1;
        font-size: 1.5rem;
    }
    .family-sites {
        position: absolute;
        right: -10%;
        top: 50%;
        transform: translateY(-50%);
    }
    .global_guide .buttons {
        flex-direction: column;
    }
    .as-container {
        /* max-width: 80vw; */
    }
    .as-container .result-container,
    .as-container .type-container,
    .as-container .option-container {
        flex-wrap: wrap;
    }
    .as-container .option-container label {
        display: block;
        width: 100%;
    }
}
@media (max-width: 600px) {
    html {
        font-size: 18px;
    }
    header {
        height: 120px;
        padding: 0 10px;
        justify-content: center;
    }

    header > div.blank,
    header > div.mark {
        display: none;
    }
    .global_guide {
        padding-top: 0;
    }
    .global_guide h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .container {
        width: 95%;
    }

    /* footer .banner ul li {
        width: 90%;
    }
    footer .banner ul li img {
        height: 120px;
        margin-bottom: 10px;
    } */
    footer .company ul li {
        width: 100%;
    }
    footer .license div {
        padding: 0 10px;
    }
    footer .license ul {
        margin: 0;
    }
    footer .license ul li {
        display: block;
        border-right: 0;
        margin-bottom: 5px;
    }

    .global_guide {
        width: 100%;
    }
    .sub-title button {
        font-size: 1.2rem;
    }

    .container form {
        width: 100%;
    }
    .container.row {
        width: 90%;
    }
    .container.list .filter {
        width: 100%;
    }
    .container.content {
        width: 95%;
    }
    .container .wrapper .label {
        margin-right: 3px;
        width: 60px;
        font-size: 0.75rem;
        word-break: keep-all;
        line-height: 1.1rem;
        /* line-break: anywhere; */
    }

    .container .wrapper button,
    .container .wrapper > .file-label,
    .container .wrapper > div.switch:not(.input) {
        display: grid;
        margin-left: 63px;
        margin-top: 3px;
        width: calc(100% - 63px);
        font-size: 0.75rem;
        align-content: center;
    }
    .container .wrapper > div.switch:not(.input) {
        width: calc(100% - 65px);
    }
    div.switch label {
        align-content: center;
        vertical-align: middle;
        text-align: center;
    }

    .container .wrapper > input[type='text'],
    .container .wrapper > input[type='password'],
    .container .wrapper > input[type='date'],
    .container .wrapper > input[type='email'],
    .container .wrapper > select,
    .container .wrapper > textarea,
    .container .wrapper > span,
    div.switch.input,
    .container .wrapper .upload-list,
    .container .wrapper .items {
        width: calc(100% - 63px);
    }
    .container .wrapper:has(button) > input,
    .container .wrapper:has(div.switch) > input,
    .container .wrapper:has(div.switch) > select,
    .container .wrapper:has(label.file-label) > div.upload-list {
        width: calc(100% - 63px) !important;
    }

    .container .tool-tip {
        margin-left: 70px;
    }
    header .mark img {
        height: 60%;
    }
    .navigation {
        display: inline-block;
        width: 100%;
        flex-wrap: wrap;
    }
    .navigation a,
    .navigation p {
        width: 50%;
        height: 3rem;
    }

    .pagination {
        gap: 3px;
    }
    .contact p {
        display: block;
        margin-bottom: 15px;
    }
    .contact span {
        display: block;
        line-height: 1.4rem;
    }
    div.message-container {
        padding: 10px 0;
    }
    .sub-header {
        padding: 5px 20px;
        gap: 5px;
    }

    .list .content {
        font-size: 16px;
    }

    .global_guide table {
        display: block;
        width: 100%;
    }
    .global_guide table tr {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
    }
    .global_guide table th {
        display: block;
        text-align: start;
        margin-bottom: 0;
        padding: 0;
    }

    .sub-content {
        font-size: 0.8rem;
        width: 100%;
    }
    input[type='date'] {
        position: relative;
    }
    input[type='date']::-webkit-clear-button,
    input[type='date']::-webkit-inner-spin-button {
        display: none;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        z-index: 1;
    }
    input[type='date']:after {
        position: absolute;
        top: 50%;
        right: 10px;
        content: '';
        width: 20px;
        height: 22px;
        background-color: var(--color-white);
        background-position: center;
        background-image: url('../icons/calender_icon.svg');
        background-size: contain;
        transform: translateY(-50%);
        z-index: 0;
    }
    .family-sites {
        position: static;
        margin: 1rem 0;
        width: 80%;
        transform: initial;
    }
    .family-sites select {
        width: 100%;
        font-size: 1.1rem;
        padding: 0.2rem;
        box-sizing: border-box;
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
    .container form .auth-list {
        font-size: 0.8rem;
    }
    .list .header {
        font-size: 1rem;
    }
    .global_guide .buttons {
        width: 80%;
    }
    .as-container.image {
        margin-left: 0;
        width: 100%;
    }
    .as-container {
        /* max-width: 90%; */
    }
    .as-container .header {
        padding: 5px;
    }
    .as-container .result-container span {
        text-align: center;
        width: 100%;
    }
    .as-container .result-container span:nth-child(2) {
        width: 100%;
    }
    .as-container .header span {
        display: block;
        text-align: center;
        line-height: 1.3rem;
    }
    .as-container .body {
        padding: 10px;
    }
    .as-container .result-container,
    .as-container .type-container,
    .as-container .option-container {
        gap: 0;
    }
    .as-container .footer {
        font-size: 0.5rem;
    }
    .popup-wrapper {
        width: 100%;
    }
    .popup.mobile {
        display: flex;
    }
    .popup.desktop {
        display: none;
    }
}
.swiper-horizontal {
    touch-action: auto;
}

.pc {
    display: inherit;
}
.mobile {
    display: none;
}
@media (max-width: 768px) {
    .pc {
        display: none;
    }
    .mobile {
        display: inherit;
    }
}
footer img {
    width: 100%;
}
footer {
    font-size: 16px;
}
@media (max-width: 1440px) {
    footer {
        font-size: 14px;
    }
}
@media (max-width: 1024px) {
    footer {
        font-size: 12px;
    }
}

footer {
    width: 90%;
    margin: auto;
}
footer.pc .footer-top {
    display: grid;
    grid-template-columns: 18em 2fr 18em;
    gap: 50px;
    padding-top: 50px;
}

footer.pc .content_1,
footer.pc .content_2 {
    word-break: keep-all;
    font-size: 1em;
    line-height: 1.6em;
    font-weight: 600;
    border: 1px solid #000;
}
footer.pc .content_1 div.title,
footer.pc .content_2 div.title {
    padding: 0.8em;
    border-bottom: 1px solid #000;
    word-break: keep-all;
    color: var(--color-white);
    text-align: center;
    font-size: 1.1em;
}
footer.pc div.title b {
    display: block;
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 1em;
}
footer.pc .content_1 div.title {
    background-color: var(--color-info);
}
footer.pc .content_2 div.title {
    background-color: var(--color-error);
}
footer.pc .content_1 ul {
    display: flex;
    flex-direction: column;
}
footer.pc .content_1 ul li {
    padding: 0.7em 0.5em;
}
footer.pc .content_2 dl {
    padding: 1.5em 0.8em;
    text-align: start;
}
footer.pc .content_2 dd {
    font-size: 1.3em;
}
footer.pc .content_2 dd::before {
    content: '▶︎';
    margin-right: 6px;
}
footer.pc .content_2 dd::after {
    content: ':';
    margin-left: 6px;
}
footer.pc .content_2 dt {
    margin: 0.3em 1em;
    font-size: 1.2em;
}
footer.pc .content_2 p {
    padding: 0 0.5em;
    word-break: keep-all;
    font-size: 1.2em;
}
footer.pc .content_2 .contact {
    text-align: center;
}
footer.pc .content_2 .contact img {
    width: 50%;
    display: block;
    margin: auto;
    margin-bottom: 1.2em;
}
footer.pc .content_2 .contact p {
    font-size: 1.7em;
    font-weight: 700;
    letter-spacing: 3px;
    word-spacing: 6px;
    white-space: nowrap;
}
footer.pc .partners {
    padding: 2.2em 0;
}
footer.pc .partners ul {
    display: flex;
    justify-content: space-around;
}
footer.pc .contents {
    margin-bottom: 1.5em;
}
footer.pc .contents ul {
    display: flex;
    justify-content: center;
    gap: 1em;
}
footer.pc .company {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    gap: 2rem;
}
footer.pc .company img {
    width: 200px;
}
footer.pc .company .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.4em;
}
footer.pc .company .information * {
    line-height: inherit;
}
footer.pc .copy {
    text-align: center;
    padding-bottom: 1.5em;
}
footer.pc .banners {
    border-top: 1px solid #999;
    padding-bottom: 5em;
}
footer.pc .banners p.description {
    padding: 1em 0;
}
footer.pc .banners ul {
    padding: 0 5em;
    display: flex;
    gap: 5em;
}

footer.mobile .services {
    width: 90%;
    margin: auto;
    margin-bottom: 2em;
}
footer.mobile .partners {
    width: 80%;
    margin: auto;
    margin-bottom: 2em;
}
footer.mobile .partners p,
footer.mobile .partners_2 p,
footer.mobile .conpanies p {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 1em;
}
footer.mobile .partners ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
footer.mobile .partners ul li a {
    width: 100%;
    height: 100%;
}
footer.mobile .partners_2 {
    width: 80%;
    margin: auto;
    margin-bottom: 2em;
}
footer.mobile .conpanies ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
footer.mobile .conpanies {
    width: 90%;
    margin: auto;
    margin-bottom: 2em;
}
footer.mobile .contents ul {
    display: flex;
    justify-content: space-around;
}
footer.mobile .company {
    font-size: 1.2em;
    line-height: 1.6em;
}
footer.mobile .company img {
    display: block;
    width: 200px;
    margin: auto;
}
footer.mobile .copy {
    padding: 1em;
    text-align: center;
}
footer.mobile img {
    width: 100%;
}
