html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%; */
    /* font: inherit; */
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a:hover,
a:active {
    text-decoration: none;
}
input,
textarea {
    padding: initial;
    margin: initial;
}

input[type='text']:focus-visible,
input[type='password']:focus-visible,
input[type='email']:focus-visible,
input[type='date']:focus-visible,
select:focus-visible,
textarea:focus-visible,
button {
    outline: none;
}
select {
    cursor: pointer;
}
input[type='radio'],
input[type='checkbox'] {
    margin: 0 5px;
    cursor: pointer;
}
a,
button {
    cursor: pointer;
    background-color: inherit;
    color: inherit;
    border: initial;
    font-size: inherit;
    padding: initial;
    margin: initial;
    vertical-align: baseline;
    line-height: initial;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    text-decoration: none;
}
a.disabled,
button.disabled {
    cursor: no-drop;
}
table,
th,
td {
    border-collapse: collapse;
    border: 1px solid var(--color-gray);
    padding: 5px;
    box-sizing: border-box;
}
.strike {
    text-decoration: line-through;
}
